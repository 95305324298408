.nav-bar {
  padding: 0;
}

@media (min-width: 900px) {
  .nav-bar {
    padding: 0 50px;
  }
}

.gene-search {
  margin: 0 16px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* Override the default placeholder styling */
.MuiInputBase-input::placeholder {
  animation-name: fade-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

/* Apply the fade-in animation when the placeholder is shown */
.MuiInputBase-input::placeholder-shown {
  animation-name: fade-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.search-bar {
  width: 100%;
  max-width: 500px;
  margin: 16px auto;
  display: flex;
  align-items: center;
}

.options-bar {
  margin: 0 auto 20px auto;
}

.logo-left,
.logo-right {
  margin-top: 16px;
  text-align: center;
}

@media (min-width: 900px) {
  .logo-right {
    order: 1;
  }
}

@media (max-width: 426px) {

  .logo-left img,
  .logo-right img {
    height: 45px;
    width: auto;
  }
}

.credible-plot {
  /* width: 800px; */
  padding: 10px;
  /* max-width: 800px; */
  /* max-height: 550px; */
  /* overflow-y: auto; */
}

div#credible-plot svg {
  margin-top: 10px;
}

.card-table {
  padding-top: 50px;
  height: 350px;
  /* max-width: 700px; */
}

.isoqtl-table {
  padding-top: 50px;
  margin-left: 50px;
  height: 500px;
  max-width: 700px;
  /* max-width: 700px; */
}

/* .sqtl-table {
  /* padding-top: 80px; */
  /* height: 90%; */
  /* max-width: 700px; */
/* } */

.info-card {
  padding: 0 16px;
  width: 80%;
  max-width: 2000px;
  margin: auto;
  margin-bottom: 50px;
}

.table-card {
  padding: 0 16px;
  max-width: 800px;
  margin: auto;
  margin-bottom: 50px;
}

@media (min-width: 900px) {
  .info-card {
    padding: 0 50px;
  }
}

.plot-container {
  width: 100%;
  height: 100%;
}