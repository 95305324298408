.splice-plot path {
    shape-rendering: auto;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #bf0603;
}

.splice-exon {
    fill: #274c77;
    stroke-width: 2;
    rx: 2px;
}

.splice-intron {
    fill: #DAE0E7;
    stroke-width: 2;
    rx: 2px;
}

.splice-text {
    font-family: 'Lato', sans-serif;
}