.link {
  stroke-width: 1.5px;
}

.link.highlight {
  stroke: #6673b5 !important;
}

.link.deprioritize {
  stroke: #d1d1d17a !important;
}

.node {
  cursor: move;
  fill: #ccc;
  stroke: #000;
  stroke-width: 1.5px;
}

.node.fixed {
  fill: #5880b4;
}

.node.highlight {
  fill: #f2cc8f;
}

@import url('https://fonts.googleapis.com/css2?family=Lato&family=Open+Sans:wght@300&display=swap');
.label {
  font-family: Lato;
  text-anchor: middle;
  fill: black;
}